import React, { Component } from "react";
import styled from "styled-components";

import { Paper } from "@mui/material";
import { Divider } from "@mui/material";

import { withConfirmationDialog } from "./../../../shared/ui/ConfirmationDialog";

import CircularProgress from "@mui/material/CircularProgress";
import SortableTree from "./../../../shared/ui/tree";
import BitArray from "../../../shared/utils/BitArray";

import ItemDialog from "./../../../components/itemDialog";
import { staticSelect } from "./../../../components/itemDialog/schemas";

import {
  Icon,
  ToolButton,
  DictionaryFilterToolButton,
  ToolButtonIcon,
  StyledButtonGroup,
} from "./../../../shared/ui/ToolBar";
import { DebouncedTextField, fSelect } from "shared/ui/Editors";

import { ReactComponent as IconSelectAll } from "@mdi/svg/svg/select-all.svg";
import { ReactComponent as IconSelectNone } from "@mdi/svg/svg/select-off.svg";
import { ReactComponent as IconSelectInv } from "@mdi/svg/svg/select-compare.svg";
import { ReactComponent as IconCancel } from "./NewIconsSvg/Cancel.svg";
import { ReactComponent as IconCheck } from "./NewIconsSvg/Check.svg";


import { ReactComponent as IconSortAsc } from "./NewIconsSvg/SortedUp.svg";
import { ReactComponent as IconSortDesc } from "./NewIconsSvg/SortedDown.svg";
import { ReactComponent as IconSortRemove } from "./NewIconsSvg/Unsorted.svg";
import { ReactComponent as IconLock } from "./NewIconsSvg/Lock.svg";
import { ReactComponent as IconFilterAxis } from "@mdi/svg/svg/image-filter-center-focus.svg";

import { ReactComponent as IconFilterRemove } from "shared/icon/filter_remove.svg";
import { ReactComponent as IconFindReplace } from "@mdi/svg/svg/find-replace.svg";

import { ReactComponent as IconEye } from "@mdi/svg/svg/eye-outline.svg";
import { ReactComponent as IconEyeOff } from "@mdi/svg/svg/eye-off-outline.svg";

import { withTheme } from "@mui/styles";



class DimensionValuesTree extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isReplaceMode: false,
      isOk: false,
      isTags: !!props.isTags,
      tagsVisibility: true,
      loading: false,
      filter: "",
      filterCondition: "contains",
      multipleValues: false,

      current: props.current?.current || null,
      statuses: [{ id: null, name: "" }],
      treeData: [],
      selectedIds: [],
      visible: [],
      checkedData: [],
      images: props.images,
      levels: null,
      bitArray: null,
      textFilterRef: { current: null },
      textReplaceRef: { current: null },
      replace: null,
      replaceValue: {
        text: "",
        cs: false,
        useRegExp: false,
        mathWholeWord: false,
        replace: "",
      },
    };
    this.updateTreeData = this.updateTreeData.bind(this);
    this.api = this.props.api;
    this.requestApi = this.props.requestApi;
    this.embedded = this.props.embedded === true;

    this.props.onResult &&
      (this.props.onResult.current = () => {
        this.handleOk();
        return true;
      });
  }

  updateTreeData(treeData) {
    this.setState({ treeData });
  }

  isValid = () =>
    this.api &&
    (this.props.dim?.id || (this.props.facts && this.props.factsItem));

  getNode(parent) {
    if (!this.isValid()) return;

    this.setState({ loading: true });

    if (this.state.isTags) {
      const bitArray = new BitArray(this.props.dim.properties.unique);
      this.props.treeData.forEach(
        (data) => data.selected && bitArray.toggle(data.id)
      );
      const selectedIds = this.props.treeData
        .filter((data) => data.selected)
        .map((data) => data.id);
      this.setState({
        bitArray,
        selectedIds,
        multipleValues: true,
        treeData: this.props.treeData,
        loading: false,
      });
      return;
    }

    if (this.props.facts) {
      const multipleValues = true;
      const bitArray = new BitArray(this.props.facts.length);
      this.props.facts.forEach((node, ndx) => {
        bitArray.set(ndx, node.properties.visible);
      });
      const data = this.getFactTreeData("");
      this.setState({
        bitArray,
        levels: 1,
        multipleValues,
        filter: "",
        treeData: data,
      });
      this.setState({ loading: false });
      return;
    }

    this.api
      .dimensionValues(this.props.dim.id, 0, 100, this.state.filter)
      .then((res) => {
        const data = this.getTreeData(res);
        const bitArray = new BitArray(this.props.dim.properties.unique);
        const v = this.props.dim.properties.filter || [];
        if (v.length) bitArray.values = v;
        if (this.props.dim.properties.inverse !== true) bitArray.not();

        this.setState({
          bitArray,
          levels: 1,
          multipleValues: !(this.props.dim.properties.multipleValues === false),
          filter: "",
          treeData: data,
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.dim || this.props.dim === prevProps.dim) &&
      (!prevProps.facts || this.props.facts === prevProps.facts)
    )
      return;

    if (this.state.isTags) return;

    if (this.props.facts) {
      const bitArray = new BitArray(this.props.facts.length);
      this.props.facts.forEach((node, ndx) => {
        bitArray.set(ndx, node.properties.visible);
      });
      this.setState({ bitArray });
      return;
    }

    const bitArray = new BitArray(this.props.dim.properties.unique);
    bitArray.values = this.props.dim.properties.filter || [];
    if (this.props.dim.properties.inverse !== true) bitArray.not();

    this.setState({ bitArray });
  }

  getFactTreeData = (filter) => {
    const f = filter && filter !== "" ? filter.toLowerCase() : null;
    return this.props.facts.reduce((result, node, ndx) => {
      const title = (
        node.properties.title ||
        node.properties.name ||
        ""
      ).toLowerCase();
      if (!f || title.includes(f))
        result.push({
          icon: () => node.properties.icon,
          name: () => title,
          actual: () => node.properties.actual !== false,
          iconType: node.type,
          noChildren: true,
          id: ndx,
        });
      return result;
    }, []);
  };

  getTreeData = (res) => {
    const getData = (i) => {
      const data = {
        ...i,
        name: () => i.value,
        icon: () => null,
        actual: () => true,
        noChildren: !i.size,
        id: i.index,
      };
      if (i.expanded) data.expanded = true;
      if (i.childrens) data.children = i.childrens.map(getData)
      return data;
    };

    const data = res?.values?.map(getData);
    return data;
  };

  componentDidMount() {
    this.getNode();
  }

  componentWillUnmount() {}

  handleCancel = () => {
    this.props.popupState.close();
  };

  toggleTagsVisibility = () => {
    this.props.toggleSelectedTagsVisibility?.();
    this.setState({ tagsVisibility: !this.state.tagsVisibility });
  };

  validateFilter = () => {
    const filter = this.state.textFilterRef.current;
    const filterType = this.props.dim?.properties?.type;
    
    if (!filter) {
      this.props.dim.properties.textFilter = {};
      return;
    }

    if (filterType === "Boolean") {
      this.props.dim.properties.textFilter = { ...filter };
      return;
    }
    
    const isNullOperator = filter.operator === "IS NOT NULL" || filter.operator === "IS NULL";
    if (filterType === "Date" || filterType === "DateTime") {
      const startValueValid = filter.start !== null && new Date(filter.start).toString() !== "Invalid Date";
      const endValueValid = filter.end !== null && new Date(filter.end).toString() !== "Invalid Date";
      if (!isNullOperator && !startValueValid) {
        this.props.dim.properties.textFilter = {};
        return;
      }
      
      const betweenStatement = filter.operator === "BETWEEN" || filter.operator === "NOT BETWEEN";
      if (betweenStatement && (!startValueValid || !endValueValid)) {
        this.props.dim.properties.textFilter = {};
        return;
      }

      this.props.dim.properties.textFilter = { ...filter };
      return;
    }

    if (!isNullOperator && (!filter.text || filter.text === null)) {
      this.props.dim.properties.textFilter = {};
      return;
    }

    this.props.dim.properties.textFilter = { ...filter };
  }
  
  handleOk = (action) => {
    if (!this.isValid()) return;

    if (this.state.isTags) {
      this.props.popupState && this.props.popupState.close();
      this.props.onTagsChange?.(this.state.selectedIds);
      return;
    }

    if (this.state.isReplaceMode === true) {
      const v = Object.assign({}, this.state.textReplaceRef?.current || {});
      this.api.findAndReplace?.(this.props.dim.id, v);
      this.handleCancel();
      return;
    }

    this.setState({ loading: true });

    if (this.props.facts) {
      const v = this.props.facts.reduce((v, node, ndx) => {
        const visible = this.state.bitArray.get(ndx);
        if (visible) v.push(node.id);
        // for real-time result
        node.properties.visible = visible;
        return v;
      }, []);
      this.api
        .setFactsVisibility(this.props.factsItem.id, v)
        .then((res) => {})
        .finally(() => {
          this.setState({ loading: false });
          this.props.popupState && this.props.popupState.close();
          this.props.onApplyFilter && this.props.onApplyFilter();
        });
      return;
    }

    const f = this.state.bitArray.copy();
    if (this.props.dim.properties.inverse !== true) f.not();

    this.props.dim.properties.filter = f.values;

    this.validateFilter();

    if (action === "clear")
      this.props.additionalFilters?.onClearFilters?.(this.props.dim);

    if (this.embedded) {
      this.api.set(this.props.dim.id, this.props.dim.properties);
    } else {
      this.api
        .set(this.props.dim.id, this.props.dim.properties)
        .then((res) => {})
        .finally(() => {
          this.setState({ loading: false });
          this.props.popupState && this.props.popupState.close();
          this.props.onApplyFilter && this.props.onApplyFilter();
        });
    }
  };

  nodeClicked = (event, rowInfo) => {
    const { multipleValues } = this.state;
    const nodeId = rowInfo.node.id;
    const bitArray = this.state.bitArray.copy();
    if (!multipleValues) bitArray.reset();
    bitArray.toggle(nodeId);
    this.state.selectedIds.find((id) => id === nodeId)
      ? this.setState({
          selectedIds: this.state.selectedIds.filter((id) => id !== nodeId),
        })
      : this.state.selectedIds.push(nodeId);
    this.setState({ bitArray });
  };

  handleSelectAll = () => {
    const bitArray = this.state.bitArray.copy();
    bitArray.reset();
    function getChildrenIds(array) {
      const ids = [];
      array.forEach(data => {
        if (data.childrens) {
          ids.push(...getChildrenIds(data.childrens))
        } else {
          ids.push(data.index);
          bitArray.toggle(data.index)
        }
      });
      return ids;
    }
    const ids = getChildrenIds(this.state.treeData);
    this.setState({
      bitArray,
      selectedIds: ids,
    });
  };

  handleSelectNone = () => {
    const bitArray = this.state.bitArray.copy();
    bitArray.fill(false);
    this.setState({ bitArray, selectedIds: [] });
  };

  handleInversion = () => {
    const bitArray = this.state.bitArray.copy();
    bitArray.not();
    function validateIds(array) {
      array.forEach(data => {
        if (!data.childrens) return;
        bitArray.toggle(data.index)
        validateIds(data.childrens)
      });
    }
    validateIds(this.state.treeData);
    const selectedIds = this.state.treeData
      .filter((data) => !this.state.selectedIds.includes(data.id))
      .map((data) => data.id);
    this.setState({ bitArray, selectedIds });
  };

  handleFilterChange = (event) => {
    const value = event.target.value;
    this.handleFilter(value);
  };

  handleRemoveTextFilter = () => {
    if (this.state.textFilterRef.current) {
      this.state.textFilterRef.current.text = "";
      this.state.textFilterRef.current.value = null;
      this.state.textFilterRef.current.operator = "ANY";
      this.handleOk("clear");
    }
  };

  handleFilter = (filter, condition) => {
    if (!this.isValid()) return;
    
    if (this.props.facts) {
      const treeData = this.getFactTreeData(filter);
      this.setState({ treeData, filter });
      return;
    }

    this.setState({ filter, loading: true });

    if (this.state.isTags) {
      const filteredData = this.props.treeData.filter((data) =>
        data.name().toLowerCase().includes(filter.toLowerCase())
      );
      this.setState({ treeData: filteredData, loading: false });
      return;
    }

    this.api
      .dimensionValues(this.props.dim.id, 0, 100, filter, condition)
      .then((res) => {
        const treeData = this.getTreeData(res);
        this.setState({ treeData });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  DictionaryContext = () => {
    const { treeData } = this.state;
    
    const inputProps = {
      disabled: false,
      fullWidth: true,
      style: "compact",
      onChange: (key, event, option) => {
        if (option) {
          this.setState({ [key]: option });
          this.handleFilter("", option);
          return;
        }

        this.handleFilter(event.target.value || "", this.state.filterCondition);
      },
      value: this.state,
    };

    return (
      <React.Fragment>
        <div style={{ display: "grid", gridTemplateColumns: "45% 55%", margin: "0 0.25em" }}>
          {fSelect({ ...inputProps }, "Условие", "filterCondition", null, { 
            select: [{ id: "contains", name: "Содержит" }, { id: "equal", name: "Равно" }, { id: "starts_with", name: "Начинается с" }],
            disableClean: true ,
            onlyIdInValue: true,
          })}
          <DebouncedTextField value={this.state.filter} label="Фильтр" onChange={(value) => this.handleFilter(value, this.state.filterCondition)} />
        </div>
        <Divider
          orientation={"horizontal"}
          flexItem={true}
        />
        {this.state.loading ? (
          <div
            style={{
              display: "flex",
              flexGrow: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="primary" size={"32px"} />
          </div>
        ) : (
          <div
            style={{
              flexGrow: 1,
              minWidth: 320,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <SortableTree
              rowHeight={28}
              treeData={treeData}
              singleLine
              onChange={this.updateTreeData}
              getNodeKey={({ node }) => node.id}
              generateNodeProps={(rowInfo) => ({
                onClick: (event) => this.nodeClicked(event, rowInfo),
                selected: this.state.bitArray.get(rowInfo.node.id),
                isShowSelectedIcon: true,
              })}
            />
          </div>
        )}
      </React.Fragment>
    );
  };

  TextReplaceContext = () => {
    const { treeData, filter, multipleValues } = this.state;

    const schema = {
      props: [
        {
          value: "text",
          type: "text",
          label: "Найти",
          autoFocus: true,
          data: { onEnter: this.handleOk.bind(this) },
        },
        {
          value: "replace",
          type: "text",
          label: "Заменить",
          data: { onEnter: this.handleOk.bind(this) },
        },
        {
          value: "useRegExp",
          type: "switch",
          label: "Регулярное выражение",
        },
        {
          value: "cs",
          type: "switch",
          label: "С учетом регистра",
          visible: (value, schema_query) => {
            return value.useRegExp !== true;
          },
        },
        {
          value: "mathWholeWord",
          type: "switch",
          label: "Слово целиком",
          visible: (value, schema_query) => {
            return value.useRegExp !== true;
          },
        },
      ],
    };

    return (
      <ItemDialog
        schema={schema}
        prop={this.state.replaceValue}
        currentValue={this.state.textReplaceRef}
        isEmbeded={true}
        type={""}
        onOk={null}
        onClose={null}
        onChanged={(value) => this.setState({ replace: value.text })}
      />
    );
  };

  TextContext = () => {
    const { isReplaceMode } = this.state;

    const equalOperator = this.props.dim?.id === "dataload_calendar_download_grid_period_year";
    const defaultFilter = equalOperator ? "=" : "LIKE"
    const value = this.props.dim?.properties?.textFilter
    ? { ...this.props.dim?.properties?.textFilter }
    : {
      text: "",
      cs: false,
      useRegExp: false,
      mathWholeWord: false,
      operator: defaultFilter,
    };
    !value.operator && (value.operator = defaultFilter);
    
    const additionalFilters = this.props.additionalFilters?.filters?.(
      this.props.dim,
      value
    );

    const schema = {
      data: (props, selfOrParentId, value, set_schema_query) => {
        const r = {};
        r.list = [
          {
            current: {
              id: "ANY",
              properties: { title: "Не выбрано", allowText: false },
            },
          },
          { current: { id: "LIKE", properties: { title: "Содержит" } } },
          { current: { id: "=", properties: { title: "Равно" } } },
          { current: { id: "<>", properties: { title: "Не равно" } } },
          { current: { id: ">", properties: { title: "Больше" } } },
          { current: { id: ">=", properties: { title: "Больше или равно" } } },
          { current: { id: "<", properties: { title: "Меньше" } } },
          { current: { id: "<=", properties: { title: "Меньше или равно" } } },
          { current: { id: "LIKE %", properties: { title: "Начинается с" } } },
          {
            current: {
              id: "NOT LIKE %",
              properties: { title: "Не начинается с" },
            },
          },
          { current: { id: "% LIKE", properties: { title: "Заканчивается на" } } },
          {
            current: {
              id: "NOT % LIKE",
              properties: { title: "Не заканчивается на" },
            },
          },
          {
            current: { id: "NOT LIKE", properties: { title: "Не содержит" } },
          },
          {
            current: {
              id: "IS NULL",
              properties: { title: "Пустое", allowText: false },
            },
          },
          {
            current: {
              id: "IS NOT NULL",
              properties: { title: "Не пустое", allowText: false },
            },
          },
        ];
        set_schema_query(r);
      },
      props: [
        {
          ...staticSelect("operator", <span>Условие</span>, "list"),
          data: { autocompleteProps: { disableClearable: true } },
        }, // readOnly: true
        {
          value: "text",
          type: "text",
          label: isReplaceMode ? (
            <span>Найти</span>
          ) : (
            <span>Текст</span>
          ),
          autoFocus: true,
          data: { onEnter: this.handleOk.bind(this), disableClean: true },
          visible: (value, schema_query) => {
            const operator = schema_query.list.find(
              (i) => i.current.id === value.operator
            );
            return operator && operator.current.properties.allowText !== false;
          },
        },
        {
          value: "cs",
          type: "boolean",
          label: <span>С учетом регистра</span>,
          visible: (value, schema_query) => {
            const operator = schema_query.list.find(
              (i) => i.current.id === value.operator
            );
            return (
              operator &&
              operator.current.properties.allowText !== false &&
              value.useRegExp !== true
            );
          },
          data: { disabled: false },
        },
        ...(Array.isArray(additionalFilters) ? additionalFilters : []),
      ],
    };

    return (
      <ItemDialog
        schema={schema}
        prop={{ current: { properties: value } }}
        currentValue={this.state.textFilterRef}
        isEmbeded={true}
        type={""}
        onOk={null}
        onClose={null}
      />
    );
  };

  NumberContext = () => {
    const value = this.props.dim?.properties?.textFilter
      ? { ...this.props.dim?.properties?.textFilter }
      : {
        text: "",
        operator: "=",
      };

    if (!value.operator) value.operator = "=";

    const schema = {
      data: (props, selfOrParentId, value, set_schema_query) => {
        const r = {};
        r.list = [
          { current: { id: "=", properties: { title: "Равно" } } },
          { current: { id: "<>", properties: { title: "Не равно" } } },
          { current: { id: ">", properties: { title: "Больше" } } },
          { current: { id: ">=", properties: { title: "Больше или равно" } } },
          { current: { id: "<", properties: { title: "Меньше" } } },
          { current: { id: "<=", properties: { title: "Меньше или равно" } } },
        ];
        set_schema_query(r);
      },
      props: [
        {
          ...staticSelect("operator", <span>Условие</span>, "list"),
          data: { autocompleteProps: { disableClearable: true } },
        }, // readOnly: true
        {
          value: "text",
          type: "text",
          label: <span>Число</span>,
          autoFocus: true,
          data: { onEnter: this.handleOk.bind(this), type: "number", disableClean: true },
          visible: (value, schema_query) => {
            const operator = schema_query.list.find(
              (i) => i.current.id === value.operator
            );
            return operator && operator.current.properties.allowText !== false;
          },
        },
      ],
    };

    return (
      <ItemDialog
        schema={schema}
        prop={{ current: { properties: value } }}
        currentValue={this.state.textFilterRef}
        isEmbeded={true}
        type={""}
        onOk={null}
        onClose={null}
      />
    );
  };

  BooleanContext = () => {
    const value = this.props.dim?.properties?.textFilter
      ? { ...this.props.dim?.properties?.textFilter }
      : {};
    const additionalFilters = this.props.additionalFilters?.filters?.(
      this.props.dim,
      value
    );
    const select = [
      { id: true, name: "Да" },
      { id: false, name: "Нет" },
    ];

    const schema = {
      props: [
        {
          value: "value",
          label: "Наличие",
          type: "fSelectCheckbox",
          data: {
            initialValue: !this.props.dim?.properties?.textFilter?.value,
            select,
          },
        },
        ...(Array.isArray(additionalFilters) ? additionalFilters : []),
      ],
    };
    
    return (
      <ItemDialog
        schema={schema}
        prop={{ current: { properties: value } }}
        currentValue={this.state.textFilterRef}
        isEmbeded={true}
        type={""}
        onOk={null}
        onClose={null}
      />
    );
  };
  
 

  DateTimeContext = () => {
    const filterType = this.props.dim?.properties?.type;
    const isDate = filterType === "Date";
    
    const { treeData, filter, multipleValues } = this.state;
    const defaultOperator = "=";
    const value = this.props.dim?.properties?.textFilter 
      ? { ...this.props.dim?.properties?.textFilter } 
      : {
          start: null,
          end: null,
          operator: defaultOperator,
      };
    !value.operator && (value.operator = defaultOperator);
    
    const schema = {
      data: (props, selfOrParentId, value, set_schema_query) => {
        const r = {};
        r.list = [
          {
            current: {
              id: "ANY",
              properties: {
                title: "Не выбрано",
                allowText1: false,
                allowText2: false,
              },
            },
          },
          {
            current: {
              id: "=",
              properties: { title: "Равно", allowText2: false },
            },
          },
          {
            current: {
              id: "<>",
              properties: { title: "Не равно", allowText2: false },
            },
          },
          {
            current: {
              id: ">",
              properties: { title: "После", allowText2: false },
            },
          },
          {
            current: {
              id: ">=",
              properties: { title: "После или равно", allowText2: false },
            },
          },
          {
            current: {
              id: "<",
              properties: { title: "До", allowText2: false },
            },
          },
          {
            current: {
              id: "<=",
              properties: { title: "До или равно", allowText2: false },
            },
          },
          { current: { id: "BETWEEN", properties: { title: "Между" } } },
          {
            current: {
              id: "NOT BETWEEN",
              properties: { title: "Не между" },
            },
          },
          {
            current: {
              id: "IS NULL",
              properties: {
                title: "Пустое",
                allowText1: false,
                allowText2: false,
              },
            },
          },
          {
            current: {
              id: "IS NOT NULL",
              properties: {
                title: "Не пустое",
                allowText1: false,
                allowText2: false,
              },
            },
          },
        ];
        set_schema_query(r);
      },
      props: [
        {
          ...staticSelect("operator", <span>Условие</span>, "list"),
          data: { autocompleteProps: { disableClearable: true } },
        }, // readOnly: true

        {
          value: "start",
          type: isDate ? "Date" : "DateTime",
          label: (value, schema_query) => {
            const operator = schema_query.list.find(
              (i) => i.current.id === value.operator
            );
            const m = {
              "=": "Дата",
              "<>": "Дата",
              ">": "После",
              ">=": "После или равно",
              "<": "До",
              "<=": "До или равно",
              BETWEEN: "После",
              "NOT BETWEEN": "До",
            };
            return m[operator?.current?.id];
          },
          autoFocus: true,
          data: { onEnter: this.handleOk.bind(this) },
          visible: (value, schema_query) => {
            const operator = schema_query.list.find(
              (i) => i.current.id === value.operator
            );
            return operator && operator.current.properties.allowText1 !== false;
          },
        },
        {
          value: "end",
          type: isDate ? "Date" : "DateTime",
          label: (value, schema_query) => {
            const operator = schema_query.list.find(
              (i) => i.current.id === value.operator
            );
            const m = {
              BETWEEN: "До",
              "NOT BETWEEN": "После",
            };
            return m[operator?.current?.id];
          },
          data: { onEnter: this.handleOk.bind(this) },
          visible: (value, schema_query) => {
            const operator = schema_query.list.find(
              (i) => i.current.id === value.operator
            );
            return operator && operator.current.properties.allowText2 !== false;
          },
        },
      ],
    };

    return (
      <ItemDialog
        schema={schema}
        prop={{ current: { properties: value } }}
        currentValue={this.state.textFilterRef}
        isEmbeded={true}
        type={""}
        onOk={null}
        onClose={null}
      />
    );
  };

  render() {
    const { treeData, filter, multipleValues, isReplaceMode } = this.state;
    const sortOrder = this.props.dim.properties.sortOrder;
    const isFixed = this.props.dim.properties.axis === "fixed";
    const isFilterAxis = this.props.dim.properties.axis === "filter";

    const isText = this.props.dim.properties.type === "Memo" || this.props.dim.properties.type === "IFrameLink" || this.props.dim.properties.type === "ObjectArray";
    const isNumber = this.props.dim.properties.type === "Number";
    const isBoolean = this.props.dim.properties.type === "Boolean";
    const isDateTime = this.props.dim.properties.type === "DateTime";
    const isDate = this.props.dim.properties.type === "Date";
    const isDictionary = this.props.dim.properties.type === "Dictionary";

    const sortAction = (id, icon, text) => {
  
      return ToolButtonIcon(
        Icon(sortOrder === id ? "primary" : "action", icon, 16),
        () => this.props.onChangeOrder?.(id),
        true,
        text,
        id
      );
  }

    const dictionaryButtons = () => (
      <React.Fragment >
        {this.state.isTags &&
          DictionaryFilterToolButton(
            this.state.tagsVisibility ? IconEyeOff : IconEye,
            this.toggleTagsVisibility,
            true,
            <span>Изменить видимость тэгов</span>,
            0
          )}
        {multipleValues &&
          DictionaryFilterToolButton(
            IconSelectAll,
            this.handleSelectAll,
            true,
            <span>Выбрать все</span>,
            1,
          )}
        {multipleValues &&
          DictionaryFilterToolButton(
            IconSelectInv,
            this.handleInversion,
            true,
            <span>Выбрать альтернативные</span>,
            2
          )}
        {multipleValues &&
         DictionaryFilterToolButton(
            IconSelectNone,
            this.handleSelectNone,
            true,
            <span>Снять выделение</span>,
            3
          )}
      </React.Fragment>
    );

    const textButtons = () => (
      <ToolButtonWrapper>
        {!this.state.isReplaceMode && (
          ToolButton(
            IconFilterRemove,
            this.handleRemoveTextFilter,
            true,
            <span>Сбросить фильтры</span>,
            4
          )
        )}
      </ToolButtonWrapper>
    );

    const buttons = () => {
      return isDictionary ? dictionaryButtons() : textButtons();
    };

    const sortToolbar = () => [
      sortAction("ASC", IconSortAsc, <span>Сортировка по возрастанию</span>),
      sortAction("DESC", IconSortDesc, <span>Сортировка по убыванию</span>),
      sortAction("none", IconSortRemove, <span>Без сортировки</span>),
      <Divider
        key="-2"
        orientation={"vertical"}
        flexItem={true}
        style={{ margin: "0 0.25em" }}
      />,
    ];

    const topBar = () => [
      <div key="sort" style={{ display: "flex", padding: "0.25em" }}>
        {!this.props.disableSearchAndReplaceButton && isText
          ? ToolButtonIcon(
              Icon(isReplaceMode ? "primary" : "action", IconFindReplace),
              () => this.setState({ isReplaceMode: !isReplaceMode }),
              true,
              <span>Найти и заменить</span>,
              "findReplace"
            )
          : null}
        {!this.props.disableSearchAndReplaceButton && isText ? (
          <Divider
            key="-1"
            orientation={"vertical"}
            flexItem={true}
            style={{ margin: "0 0.25em" }}
          />
        ) : null}

        {!isFilterAxis && sortToolbar()}

        {ToolButtonIcon(
          Icon(isFixed ? "primary" : "action", IconLock, 16),
          () => this.props.onChangeFixed?.(!isFixed),
          true,
          isFixed ? (
            <span>Разморозить</span>
          ) : (
            <span>Заморозить</span>
          ),
          "froze"
        )}
        {!this.props.disableMoveFiltersButton &&
          ToolButtonIcon(
            Icon(isFilterAxis ? "primary" : "action", IconFilterAxis),
            () => this.props.onChangeFilterAxis?.(!isFilterAxis),
            true,
            isFilterAxis ? (
              <span>Разморозить</span>
            ) : (
              <span>Переместить в фильтры</span>
            ),
            "moveToFilter"
          )}
      </div>,
      <Divider
        key="-1"
        orientation={"horizontal"}
        style={{ margin: "0.25em 0" }}
      />,
    ];

    const bottomBar = () => (
      <StyledButtonGroup style={{ borderTopWidth: 1 }}>
        {buttons()}
        <div style={{ flex: 1 }} />
        {!this.embedded && (
          <ToolButtonWrapper>
            {ToolButton(
              IconCancel,
              this.handleCancel,
              true,
              <span>Отменить</span>,
              4,
              "error",
            )}
          </ToolButtonWrapper>
        )}
        {!this.embedded && (
          <ToolButtonWrapper>
            {ToolButton(
              IconCheck,
              this.handleOk,
              isReplaceMode ? this.state.replace?.length > 0 : true,
              <span>Применить</span>,
              5,
              "primary"
            )}
          </ToolButtonWrapper>
        )}
      </StyledButtonGroup>
    );

    return (
      <Paper
        square
        elevation={this.embedded ? 0 : 1}
        style={{
          minWidth: "200px",
          flexGrow: 1,
          minHeight: isDictionary ? "400px" : null,
          height: isDictionary ? "400px" : null,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!this.embedded && !this.props.hideTopBar && topBar()}
        {isDictionary && this.DictionaryContext()}
        {isNumber && this.NumberContext()}
        {isText &&
          (isReplaceMode ? this.TextReplaceContext() : this.TextContext())}
        {isBoolean && this.BooleanContext()}
        {(isDateTime || isDate) && this.DateTimeContext()}
        {bottomBar()}
      </Paper>
    );
  }
}

const ToolButtonWrapper = styled("div")`
  svg {
    width: 16px !important;
    height: 16px !important;
  }
`;

export default withTheme(withConfirmationDialog(DimensionValuesTree));
